import { FC, useEffect, memo } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { TextField as TextFieldType } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core';
import { useAppConfig } from '../context/AppState/AppContext';
import { useNTFApi } from '../hooks/useNTFApi';

interface Props {
  field: TextFieldType;
}

const useStyles = makeStyles({
  root: (props: Props) => ({
    '& fieldset': {
      borderWidth: props.field.config.styling?.borderWidth
        ? `${props.field.config.styling?.borderWidth}px !important`
        : undefined,
      borderRadius: props.field.config.styling?.borderRadius
        ? `${props.field.config.styling?.borderRadius}px`
        : undefined,
      borderColor: props.field.config.styling?.borderColor
        ? `${props.field.config.styling?.borderColor} !important`
        : undefined,
      borderStyle: props.field.config.styling?.borderStyle,
      '& legend': {
        ...props.field.config.styling?.labelStyling,
      },
    },
  }),
});

const TextAreaField: FC<any> = ({ field }: Props) => {
  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootStyling } =
    field.config.styling ?? {};

  const classes = useStyles({ field });
  const { state, dispatch } = useFormData();
  const { state: appState } = useAppConfig();
  const { updateJobTimer } = useNTFApi();
  const fieldError = state.errors.get(field.id);
  const TEXT_AREA_PADDING_PERCENT = 0.85;

  useEffect(() => {
    if (field.config.readonly) return;
    function validateField(): string {
      const { required, minLength, maxLength } = field.config;
      if (maxLength && field.value.length > field.config.maxLength)
        return t('error valueLong', {
          max: field.config.maxLength.toString(),
        });
      if (minLength && field.value.length < field.config.minLength)
        return t('error valueShort', {
          min: field.config.minLength.toString(),
        });
      if (required && !field.value) return t('error empty');
      return '';
    }
    if (field.config.readonly) return; //skip valiation if readonly
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch]);

  function handleChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
    // The product team requested that the last updated job timestamp be updated whenever a text field is edited.
    // See workflow-engine#35
    updateJobTimer(appState.processId);
  }
  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: 0,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    top: {
      top: 0,
      left: 5,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 5,
      transform: 'translate(0, 100%)',
    } as const,
    'inside-top': {
      top: 5,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];

  let fontSize = field.config.styling?.fontSize ?? 16;
  if (fontSize <= 0) fontSize = 1;
  let rows = field.config.styling?.height
    ? Math.floor((field.config.styling?.height * TEXT_AREA_PADDING_PERCENT) / fontSize) - 1
    : 4;
  if (rows <= 0) rows = 4;

  return (
    <>
      <TextField
        className={classes.root}
        fullWidth
        multiline
        maxRows={rows}
        minRows={rows}
        key={field.id + '_field'}
        variant="outlined"
        size="small"
        value={field.value}
        label={['within-top', undefined].includes(labelPosition) ? field.config.title : undefined}
        InputLabelProps={{
          shrink: true,
          style: {
            top: 0,
            left: 14,
            transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
            lineHeight: 1,
            ...labelStyling,
            fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
            marginLeft: borderWidth,
          },
        }}
        onChange={handleChange}
        error={!!fieldError}
        disabled={field.config.readonly}
        helperText={fieldError}
        InputProps={{
          style: {
            ...rootStyling,
            borderRadius,
            overflowY: 'hidden',
            overflowX: 'hidden',
          },
        }}
        inputProps={{
          maxLength: field.config.maxLength ?? 255,
          style: {
            textAlign: field.config?.styling?.textAlign,
            textDecorationLine: field.config?.styling?.textDecorationLine,
            margin: borderWidth,
          },
        }}
      />
      {!['within-top', undefined].includes(labelPosition) && (
        <InputLabel
          style={{
            position: 'absolute',
            maxWidth: 'none',
            fontSize: '0.75rem',
            transition: 'none',
            whiteSpace: 'nowrap',
            ...labelPositionStyling,
            ...labelStyling,
            width: 'unset',
            paddingRight: 'unset',
          }}
        >
          {`${field.config.title} ${field.config.required ? '*' : ''}`}
        </InputLabel>
      )}
    </>
  );
};

export default memo(TextAreaField);
