import { trackPromise } from 'react-promise-tracker';
import axios from '../../lib/axios';
import { getMetadata } from '@dispatcher-stratus/metadata';
import jwt from 'jsonwebtoken';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { FileField, FileFieldValue, FormField, FormFieldTypeEnum } from '../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormState/FormDataContext';
import { useFormUtils } from './useFormUtils';
import { t } from 'i18next';
import { useAppArgs } from './useAppArgs';
import { useMemo } from 'react';
import { useMetadataApi } from './useMetadataApi';
import { useNTFApi } from './useNTFApi';
import { useAuthContext } from '../context/AuthState/AuthContext';
import { useAppConfig } from '../context/AppState/AppContext';
import { useHistory } from 'react-router-dom';
import { FormDataType } from '../../interfaces';
import { usePersistForm } from './usePersistForm';
const { v4: uuidv4 } = require('uuid');
const Fp = require('lodash/fp');

export const useFormApi = () => {
  const { uploadFormToProcessMetadata, uploadFileMetadata, uploadFileToProcessMetadata } =
    useMetadataApi();
  const { startWorkflowAndAwaitReady, uploadFile, uploadFileToDroppoint, getAllFilesFromDrop, addFilesToLicenseCount, updateJobTimer } = useNTFApi();
  const { state: authState } = useAuthContext();
  const { state: appState } = useAppConfig();
  const { state: formState, dispatch } = useFormData();
  const history = useHistory();
  const { fallbackDomain, domain, metadata: metadataArg, processId, tenant } = useAppArgs();
  const { compileFormData, massageFormInput } = useFormUtils();
  const { persistFormDefinition } = usePersistForm();

  const formApi = useMemo(() => {
    const getForm = async (fid: string) => {
      const response = await axios
        .get(`https://${metadataArg.tenant.region ?? 'us-east-1'}.forms-service.${fallbackDomain}/api/forms/${fid}`, {
          headers: {
            Authorization: 'Bearer ' + metadataArg.token,
            'stc-tenant-slug': metadataArg.tenant.slug,
          },
          withCredentials: false,
        })
        .catch((err) => {
          console.error(err);
          throw new Error(t('error fetchForm'));
        });
      if (response.data.code) {
        console.error(response.data);
        throw new Error(t('error fetchForm'));
      }
      return response.data;
    };

    async function initializeForm(rawForm: any, sessionID: string, workflowID: string, userID: string, fileID: string) {
      let metadata = {
        environment: new Map<any, any>(),
        records: new Map<string, any>(),
      };
      metadata = await getMetadata({
        workflowId: workflowID,
        userId: userID,
        processId: sessionID,
        fileId: fileID,
        domain: fallbackDomain,
        region: metadataArg.tenant.region,
      });

      console.log('getMetadata: ', metadata);

      const massagedForm = await massageFormInput(rawForm, metadata);
      formState.debug && console.log('[DEBUG] massaged form input:', massagedForm);
      console.log('initializeForm: ', massagedForm);
      return massagedForm;
    }

    async function processFileFields(formFields: FormField[] = [], drop = {}) {
      /**
       * First new file hit upload endpoint with just fileId and get droppoint information
       * For each new file, hit upload endpoint with droppoint information
       * upload each file using droppoint information
       * add droppoint information to report
       * function needs to return droppoint information for report, array for formdata
       */
      const fields: FormField[] = [];
      const files: { fileId: string; file: File; droppointInfo: any }[] = [];
      let droppointCreds = drop;
      for (const field of formFields) {
        if (field.type !== FormFieldTypeEnum.file) {
          fields.push(field);
          continue;
        }
        const updatedFiles: FileFieldValue[] = [];
        for (const file of field?.value ?? []) {
          if (file.new) {
            //upload file
            const fileId = uuidv4();
            const droppoint = Fp.isEmpty(droppointCreds)
              ? await uploadFile(fileId)
              : await uploadFile(fileId, droppointCreds);
            droppointCreds = {
              dropid: droppoint.dropid,
              username: droppoint.username,
              password: droppoint.password,
            };

            updatedFiles.push({
              new: false,
              fileId: fileId,
            });
            files.push({
              fileId: fileId,
              file: file.file,
              droppointInfo: droppoint.info,
            });
          } else {
            updatedFiles.push(file);
          }
        }
        fields.push({ ...(field as FileField), value: updatedFiles });
      }

      return { fields: fields, files: files, droppointCreds: droppointCreds };
    }

    async function uploadFiles(files: { fileId: string; file: File; droppointInfo: any }[], processId?: string, initialFile = false) {
      let totalSize = 0;
      const fileNameList: Array<any> = [];
      let fileNameConcactString: string = '';
      for (const fileObj of files) {
        const { fileId, file, droppointInfo } = fileObj;
        await uploadFileToDroppoint(file, droppointInfo);
        totalSize += file.size;
        fileNameList.push(file.name);
        const fileMetadata = {
          itemType: 'FILE',
          data: {
            size: file.size,
            ext: file.name.slice(file.name.lastIndexOf('.')),
            name: file.name.split('.')[0],
            fullname: file.name,
            uuid: fileId,
            date: Date.now(),
            uploadedBy: jwt.decode(authState.token!)?.sub,
            initialFormSubmission: !!initialFile
          },
        };
        await uploadFileMetadata(fileId, fileMetadata, processId);
        // await uploadFileToProcessMetadata(fileId, processId)
      }
      if (fileNameList.length) {
        fileNameConcactString = fileNameList.join(',');
      }
      if (totalSize) {
        await addFilesToLicenseCount(totalSize, fileNameConcactString);
      }
    }

    async function submitForm(
      formFields: FormField[],
      {
        fileId,
        sessionId,
        region,
        skipMetadataFetch = false,
      }: {
        url: string;
        token: string;
        sessionId: string;
        fileId: string;
        region: string;
        skipMetadataFetch?: boolean;
      },
    ) {
      // separate file fields from form fields
      // for each field, upload new files, then replace value of field with file Ids
      // add file fields back to form fields
      console.log('getting droppoint...');
      const {
        fields: updatedFields,
        files,
      }: {
        fields: FormField[];
        files: { fileId: string; file: File; droppointInfo: any }[];
        droppointCreds: any;
      } = await processFileFields(formFields);

      console.log('compiling form data...');
      const formdata = compileFormData(updatedFields);
      formState.debug && console.log('[DEBUG] compiled form data', formdata);

      console.log('uploading files...');

      for (const fileObj of files) {
        const { fileId, file, droppointInfo } = fileObj;
        await uploadFileToDroppoint(file, droppointInfo);
        const fileMetadata = {
          itemType: 'FILE',
          data: {
            size: file.size,
            ext: file.name.slice(file.name.lastIndexOf('.')),
            name: file.name.split('.')[0],
            fullname: file.name,
            uuid: fileId,
            initialFormSubmission: true
          },
        };
        await uploadFileMetadata(fileId, fileMetadata, sessionId);
        await uploadFileToProcessMetadata(fileId, sessionId);
      }

      let fileMetadataPayload = {
        data: {},
      };
      if (!skipMetadataFetch) {
        const processMetadata = await trackPromise(
          axios.get(
            `https://${region ?? 'us-east-1'}.metadata-api.${fallbackDomain}/api/v1/file/${sessionId}/${fileId}`,
          ),
        ).catch((err) => {
          console.error('Error fetching process metadata:', err);
          throw new Error(t('error fetchMetadata'));
        });

        console.log('processMetadata: ', processMetadata);

        fileMetadataPayload = {
          data: {
            ...processMetadata.data.data,
            form: { ...processMetadata.data.data.form, ...formdata },
          },
        };
      } else {
        fileMetadataPayload.data = {
          form: formdata,
        };
      }
      formState.debug && console.log('[DEBUG] Process metadata payload', fileMetadataPayload);

      const putFileData = await trackPromise(
        axios.put(
          `https://${region ?? 'us-east-1'}.metadata-api.${fallbackDomain}/api/v1/file/${sessionId}/${fileId}`,
          fileMetadataPayload,
        ),
      ).catch((err) => {
        console.error('Error uploading process metadata:', err);
        throw new Error(t('error uploadMetadata'));
      });
      formState.debug && console.log('[DEBUG] Process metadata upload response:', putFileData);

      // persist original form definition.
      if (formState.originalFormDef) {
        const persistFormResponse = await trackPromise(
          persistFormDefinition(appState.workflowId, processId, formState.originalFormDef, region ?? 'us-east-1', fallbackDomain),
        ).catch((err) => {
          console.error('Error persisting form definition:', err);
        });
        console.log('persistFormResponse: ', persistFormResponse);
      }
      
      // await sendReport(url, token, '');

      // if (!mock || window.location.hostname !== 'localhost')
      //   window.location.href = `https://mfp.${fallbackDomain}/processing?arn=${sessionId}`;
    }

    async function sendReport(url: string, token: string, userToken: string, bodyResponse?: any) {
      const body = {
        token,
        userToken,
        success: true,
        response: bodyResponse ?? '',
      };
      const response = await trackPromise(axios.post(url, body)).catch((err) => {
        console.error('Failed to send report:', err.response.status, err.response.data, err);
        throw new Error(t('error sendReport'));
      });
      formState.debug && console.log('[DEBUG] Report response:', response.data);
      return response;
    }

    async function uploadMetadata(
      formdata: FormData,
      workflowId: string,
      sessionId: string,
      region: string,
      skipMetadataFetch: boolean,
    ) {
      let processMetadataPayload = {
        data: {},
      };
      if (!skipMetadataFetch) {
        const processMetadata = await trackPromise(
          axios.get(
            `https://${region ?? 'us-east-1'}.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${sessionId}`,
          ),
        ).catch((err) => {
          console.error('Error fetching process metadata:', err);
          throw new Error(t('error fetchMetadata'));
        });

        processMetadataPayload = {
          data: {
            ...processMetadata.data.data,
            form: { ...processMetadata.data.data.form, ...formdata },
          },
        };
      } else {
        processMetadataPayload.data = {
          form: formdata,
        };
      }
      formState.debug && console.log('[DEBUG] Process metadata payload', processMetadataPayload);

      const putFileData = await trackPromise(
        axios.put(
          `https://${region ?? 'us-east-1'}.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${sessionId}`,
          processMetadataPayload,
        ),
      ).catch((err) => {
        console.error('Error uploading process metadata:', err);
        throw new Error(t('error uploadMetadata'));
      });
      formState.debug && console.log('[DEBUG] Process metadata upload response:', putFileData);
      return putFileData.data;
    }

    async function submitInternalForm(form: FormDataType) {
      console.log('getting droppoint...');
      const {
        fields: updatedFields,
        files,
        droppointCreds,
      }: {
        fields: FormField[];
        files: { fileId: string; file: File; droppointInfo: any }[];
        droppointCreds: any;
      } = await processFileFields(form.formDefinition.fields);

      console.log('compiling form data...');
      const formdata = compileFormData(updatedFields);

      console.log('starting process...');
      const { arn, callbackUrl, token } = await startWorkflowAndAwaitReady();

      console.log('uploading files...');

      await uploadFiles(files, arn, true);

      console.log('uploading metadata...');

      const response = await uploadFormToProcessMetadata(arn, appState.formId, formdata, {
        internalform: {
          formId: appState.formId,
          formTitle: form.title,
          formUrl: appState.startUrl,
        },
      });
      console.log('metadata uploaded', response, token?.substring(token.length - 5));

      if (!Fp.isEmpty(droppointCreds.dropid)) {
        const dropFiles = await getAllFilesFromDrop({
          id: droppointCreds.dropid,
          username: droppointCreds.username,
          password: droppointCreds.password,
        });

        const fileUploadBody = {
          files: {
            Normal: dropFiles,
          },
          drop: {
            Normal: {
              id: droppointCreds.dropid,
              username: droppointCreds.username,
              password: droppointCreds.password,
            },
          },
        };

        await sendReport(callbackUrl, token, authState.token!, !Fp.isEmpty(droppointCreds) ? fileUploadBody : '');
      } else {
        await sendReport(callbackUrl, token, authState.token!);
      }

      // persist original form definition.
      if (formState.originalFormDef) {
        const persistFormResponse = await trackPromise(
          persistFormDefinition(appState.workflowId, arn, formState.originalFormDef, tenant.region ?? 'us-east-1', fallbackDomain),
        ).catch((err) => {
          console.error('Error persisting form definition:', err);
        });
        console.log('persistFormResponse: ', persistFormResponse);
      }

      //send metrics to logging service
      await sendMetric('internal');

      history.push('/success', {
        node: 'jobticket',
        tenant: appState.tenant,
        urlId: appState.urlId,
        prvUrl: window.location.href,
      });
    }

    async function submitExternalForm(form: FormDataType) {
      console.log('getting droppoints...');
      const {
        fields: updatedFields,
        files,
        droppointCreds,
      }: {
        fields: FormField[];
        files: { fileId: string; file: File; droppointInfo: any }[];
        droppointCreds: any;
      } = await processFileFields(form.formDefinition.fields);

      console.log('compiling form data...');
      const formdata = compileFormData(updatedFields);
      console.log('starting process...');
      const { arn, callbackUrl, token } = await startWorkflowAndAwaitReady();

      console.log('uploading files...');

      await uploadFiles(files, arn, true);

      console.log('uploading metadata...');

      const response = await uploadFormToProcessMetadata(arn, appState.formId, formdata, {
        externalform: {
          formId: appState.formId,
          formTitle: form.title,
          formUrl: appState.startUrl,
        },
      });
      console.log('metadata uploaded', response, token?.substring(token.length - 5));

      if (!Fp.isEmpty(droppointCreds.dropid)) {
        const dropFiles = await getAllFilesFromDrop({
          id: droppointCreds.dropid,
          username: droppointCreds.username,
          password: droppointCreds.password,
        });

        const fileUploadBody = {
          files: {
            Normal: dropFiles,
          },
          drop: {
            Normal: {
              id: droppointCreds.dropid,
              username: droppointCreds.username,
              password: droppointCreds.password,
            },
          },
        };

        await sendReport(callbackUrl, token, authState.token!, !Fp.isEmpty(droppointCreds) ? fileUploadBody : '');
      } else {
        await sendReport(callbackUrl, token, authState.token!);
      }

      // persist original form definition.
      if (formState.originalFormDef) {
        const persistFormResponse = await trackPromise(
          persistFormDefinition(appState.workflowId, arn, formState.originalFormDef, tenant.region ?? 'us-east-1', fallbackDomain),
        ).catch((err) => {
          console.error('Error persisting form definition:', err);
        });
        console.log('persistFormResponse: ', persistFormResponse);
      }
      
      //send metrics to logging service
      await sendMetric('external');

      history.push('/success', {
        node: 'jobticket',
        tenant: appState.tenant,
        urlId: appState.urlId,
        prvUrl: window.location.href,
      });
    }

    async function saveForm(fields: FormField[], drop: any) {
      console.log('getting droppoint...');
      const {
        fields: updatedFields,
        files,
        droppointCreds,
      }: {
        fields: FormField[];
        files: { fileId: string; file: File; droppointInfo: any }[];
        droppointCreds: any;
      } = await processFileFields(fields, drop);
      const compiledFormFields = await compileFormData(updatedFields);
      await uploadFiles(files, appState.processId);
      await uploadFormToProcessMetadata(appState.processId, appState.formId, compiledFormFields);
      await updateJobTimer(appState.processId);

      if (!Fp.isEmpty(droppointCreds.dropid)) {
        const dropFiles = await getAllFilesFromDrop({
          id: droppointCreds.dropid,
          username: droppointCreds.username,
          password: droppointCreds.password,
        });

        const fileUploadBody = {
          files: {
            Normal: dropFiles,
          },
          drop: {
            Normal: {
              id: droppointCreds.dropid,
              username: droppointCreds.username,
              password: droppointCreds.password,
            },
          },
        };

        await sendReport(
          appState.returnUrl,
          appState.returnToken,
          authState.token!,
          !Fp.isEmpty(droppointCreds) ? fileUploadBody : '',
        );
      } else {
        await sendReport(appState.returnUrl, appState.returnToken, authState.token!);
      }

      for (const field of updatedFields) {
        if (field.type === FormFieldTypeEnum.file) {
          dispatch({
            type: FormActionType.SET_FIELD_VALUE,
            payload: {
              id: field.id,
              value: field.value,
            },
          });
        }
      }
      if (window.top) {
        console.log(`https://${appState.tenant.slug}.${domain}`);
        window.top.postMessage(
          {
            source: 'forms-app-editForm-save',
            payload: { status: 'success' },
          },
          `https://${appState.tenant.slug}.tenant.${domain}`,
        );
      }
    }

    async function sendMetric(type: 'internal' | 'external') {
      try {
        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) throw new Error('Invalid token');

        await axios.post(
          `https://${appState.tenant.region}.forms-service.${fallbackDomain}/api/metrics/${type}`,
          {
            workflowId: appState.workflowId,
            tenantId: appState.tenant.id,
            userId: user.sub,
            nodeId: appState.nodeId,
          },
          {
            headers: {
              Authorization: 'Bearer ' + authState.token,
              'stc-tenant-slug': appState.tenant.slug,
            },
            withCredentials: false,
          },
        );
      } catch (err) {
        console.error('Failed to send metric', err);
      }
    }

    return {
      initializeForm,
      getForm,
      submitForm,
      uploadMetadata,
      sendReport,
      submitInternalForm,
      submitExternalForm,
      saveForm,
      processFileFields,
      uploadFileToDroppoint,
      uploadFiles,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState, authState]);
  return formApi;
};
