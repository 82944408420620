import { FC, memo, useState, useMemo, useEffect } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { FileField as FileFieldType, FileFieldValue } from '../../interfaces/FormFieldTypes';
import { TextField } from '@dispatcher-stratus/stratus-react';
import { useMetadataApi } from '../hooks/useMetadataApi';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  FormHelperText,
  Link,
  InputLabel,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import { useAppConfig } from '../context/AppState/AppContext';
import { useAppArgs } from '../hooks/useAppArgs';
import { DeleteIcon } from '../icons/DeleteIcon';
import { AttachmentIcon } from '../icons/AttachmentIcon';
import { useNTFApi } from '../hooks/useNTFApi';
interface Props {
  field: FileFieldType;
}

const useStyles = makeStyles({
  root: (props: Props) => ({
    '& fieldset': {
      borderWidth: props.field.config.styling?.borderWidth
        ? `${props.field.config.styling?.borderWidth}px !important`
        : undefined,
      borderRadius: props.field.config.styling?.borderRadius
        ? `${props.field.config.styling?.borderRadius}px`
        : undefined,
      borderColor: props.field.config.styling?.borderColor
        ? `${props.field.config.styling?.borderColor} !important`
        : undefined,
      borderStyle: props.field.config.styling?.borderStyle,
      '& legend': {
        ...props.field.config.styling?.labelStyling,
      },
    },
  }),
});

const FileField: FC<any> = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const [flag, setFlag] = useState(false);
  const { state: appState } = useAppConfig();
  const { updateJobTimer } = useNTFApi();

  useEffect(() => {
    setFlag((prev) => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const fieldError = field && state.errors.get(field.id);

  const validationError = useMemo(() => {
    const { required } = field.config;
    if (required && !field.value.length) return t('error empty');
    return '';
  }, [field.value, field.config]);

  useEffect(() => {
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: validationError,
      },
    });
  }, [field, validationError, dispatch]);

  function handleFileRemove(value: FileFieldValue, index: number) {
    const files = [...field.value];
    if (value.new && files.length > index) {
      //filter out file passed in param
      const file = files.splice(index, 1)[0];
      if (!file.new || file.file.name !== value.file.name) {
        return;
      }
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: files,
        },
      });
      updateJobTimer(appState.processId);
    }
  }

  function handleAddFile(value: File) {
    if (!value) {
      return;
    }
    console.log(value);
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: [
          ...field.value,
          {
            new: true,
            file: value,
          },
        ],
      },
    });
    // The product team requested that the last updated job timestamp be updated whenever a file is added to a form.
    // See workflow-engine#35
    updateJobTimer(appState.processId);
  }
  console.log('field value', field.value);
  // multiple={props.field.config.allowMultipleFiles}
  // required={props.field.config.required}

  return (
    <Grid container direction="column" key={field.id + '_field'}>
      <List style={{ maxWidth: '100%' }}>
        {field.value.map((value, index) => (
          <FileRow field={field} key={index} file={value} index={index} handleFileRemove={handleFileRemove} />
        ))}
        {(field.config.allowMultipleFiles || field.value.length === 0) && (
          <FileInput
            key={`file-${flag}`}
            field={field}
            index={field.value.length}
            onAddInput={handleAddFile}
            error={!!fieldError}
            helperText={fieldError}
          />
        )}
      </List>
    </Grid>
  );
};

function FileRow(props: {
  field: FileFieldType;
  file: FileFieldValue;
  index: number;
  handleFileRemove: (file: FileFieldValue, index: number) => void;
}) {
  const { field, file, index, handleFileRemove } = props;
  const { getFileMetadata } = useMetadataApi();
  const classes = useStyles(props);
  const { state: appState } = useAppConfig();
  const { domain } = useAppArgs();
  const { state } = useFormData();

  const { data, isLoading } = useQuery(
    ['fileMetadata', !file.new && file.fileId],
    async () => await getFileMetadata(!file.new ? file.fileId : ''),
    {
      enabled: !file.new,
    },
  );

  if (!file.new && !isLoading && !data.data) {
    return <></>;
  }

  const openAttachment = (file: FileFieldValue) => {
    console.log('openAttachment: ', file);
    console.log(`https://${appState.tenant.slug}.${domain}`);
    console.log(window);
    if (!file.new && !isLoading && window.top) {
      window.top.postMessage(
        {
          source: 'forms-app-editForm-file',
          payload: { fileId: file.fileId },
        },
        `https://${appState.tenant.slug}.tenant.${domain}`,
      );
    }
  };

  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootStyling } =
    props.field.config.styling ?? {};

  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: 0,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    top: {
      top: 0,
      left: 0,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 0,
      transform: 'translate(0, 100%)',
    } as const,
    'inside-top': {
      top: 0,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];
  return field.config.allowMultipleFiles ? (
    <Grid container direction="row" wrap="nowrap" style={{ width: field.config.styling?.width }}>
      <Grid item xs>
        <div className="flex flex-col relative">
          <ListItem>
            <ListItemText>
              <span>
                {!file.new ? (
                  <Link
                    href="/#"
                    underline="always"
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      openAttachment(file);
                    }}
                  >
                    {isLoading ? t('Loading...') : data.data.fullname}
                  </Link>
                ) : (
                  <>{file?.file?.name}</>
                )}
              </span>
            </ListItemText>
          </ListItem>
        </div>
      </Grid>
      {file.new && (
        <Grid className="flex items-center" item>
          <IconButton color="primary" onClick={() => handleFileRemove(file, index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item xs>
        <div className="flex flex-col relative">
          {/* <div
            className="flex items-center"
            style={{
              overflowY: 'hidden',
              overflowX: 'hidden',
              ...field.config.styling,
            }}
          > */}
          {/* <span
              style={{
                overflowY: 'hidden',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                margin: field.config.styling?.borderWidth,
                padding: '10.5px 9px', // copy styling of `MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense`
              }}
            > */}
          {!file.new ? (
            <Link
              href="/#"
              underline="always"
              onClick={() => {
                if (isLoading) {
                  return;
                }
                openAttachment(file);
              }}
            >
              {isLoading ? t('Loading...') : data.data.fullname}
            </Link>
          ) : (
            <div className="relative">
              <TextField
                className={classes.root}
                label={
                  ['within-top', undefined].includes(labelPosition)
                    ? `${props.field.config.title} ${props.field.config.required ? '*' : ''}`
                    : undefined
                }
                fullWidth
                value={file?.file?.name}
                type={'text'}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    top: 0,
                    left: 14,
                    transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
                    lineHeight: 1,
                    ...labelStyling,
                    fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
                    marginLeft: borderWidth,
                  },
                }}
                size="small"
                disabled={true}
                InputProps={{
                  style: {
                    height: '2em',
                    ...rootStyling,
                    borderRadius,
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                    width: state.advanced ? props.field.config?.styling?.width : '',
                  },
                }}
                inputProps={{
                  style: {
                    textAlign: props.field.config?.styling?.textAlign,
                    textDecorationLine: props.field.config?.styling?.textDecorationLine,
                    margin: borderWidth,
                  },
                }}
              />
              {!['within-top', undefined].includes(labelPosition) && (
                <InputLabel
                  style={{
                    position: 'absolute',
                    maxWidth: 'none',
                    fontSize: '0.75rem',
                    transition: 'none',
                    whiteSpace: 'nowrap',
                    ...labelPositionStyling,
                    ...labelStyling,
                    width: 'unset',
                    paddingRight: 'unset',
                  }}
                >
                  {`${props.field.config.title} ${props.field.config.required ? '*' : ''}`}
                </InputLabel>
              )}
            </div>
          )}
          {/* </span> */}
          {/* </div> */}
        </div>
      </Grid>
      {file.new && (
        <Grid item>
          <IconButton color="primary" onClick={() => handleFileRemove(file, index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function FileInput(props: {
  field: FileFieldType;
  onAddInput: (value: any) => void;
  index: number;
  error: boolean;
  helperText?: string;
}) {
  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootStyling } =
    props.field.config.styling ?? {};

  const classes = useStyles(props);
  const { state } = useFormData();

  const [inputValue, setInputValue] = useState<File>();
  const [fileError, setFileError] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | undefined>(undefined);
  const fileTypes = props.field.config.fileTypes
    .split(',')
    .filter((fileType) => !!fileType)
    .map((fileType) => fileType.trim());

  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: 0,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    top: {
      top: 0,
      left: 0,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 0,
      transform: 'translate(0, 100%)',
    } as const,
    'inside-top': {
      top: 0,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item xs>
        <div className="flex flex-col relative">
          <div
            style={{
              marginTop:
                props.field.config.allowMultipleFiles && labelPosition === 'top'
                  ? props.field.config.styling?.labelStyling.fontSize
                  : 10,
            }}
            className={`relative`}
          >
            <TextField
              className={`${classes.root}`}
              label={
                ['within-top', undefined].includes(labelPosition)
                  ? `${props.field.config.title} ${props.field.config.required ? '*' : ''}`
                  : undefined
              }
              fullWidth
              value={inputValue?.name ?? ''}
              type={'text'}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  top: 0,
                  left: 14,
                  transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
                  lineHeight: 1,
                  ...labelStyling,
                  fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
                  marginLeft: borderWidth,
                },
              }}
              size="small"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <label className="cursor-pointer">
                      <input
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          const file = Array.from(e.target.files ?? [])[0];
                          const hasExtension = file?.name?.indexOf('.') > 0;
                          if (
                            !hasExtension ||
                            (hasExtension &&
                              file?.name &&
                              !!fileTypes.length &&
                              !fileTypes.includes(file?.name?.split('.')?.pop()?.toLowerCase() ?? ''))
                          ) {
                            setFileError(true);
                            setFileErrorMessage('Invalid file type');
                          } else if (
                            props.field.config.maxFileSizeUnits === 'MB' &&
                            Math.ceil(file.size / (1024 * 1024)) > props.field.config.maxFileSize
                          ) {
                            setFileError(true);
                            setFileErrorMessage('File too large');
                          } else if (
                            props.field.config.maxFileSizeUnits === 'KB' &&
                            Math.ceil(file.size / 1024) > props.field.config.maxFileSize
                          ) {
                            setFileError(true);
                            setFileErrorMessage('File too large');
                          } else {
                            setFileError(false);
                            setFileErrorMessage(undefined);
                          }
                          setInputValue(file);
                          props.onAddInput(file);
                        }}
                        type="file"
                      />
                      <Tooltip title={'Browse'}>
                        <span>
                          <AttachmentIcon color="primary" />
                        </span>
                      </Tooltip>
                    </label>
                  </InputAdornment>
                ),
                style: {
                  height: '2em',
                  ...rootStyling,
                  borderRadius,
                  overflowY: 'hidden',
                  overflowX: 'hidden',
                  width: state.advanced ? props.field.config?.styling?.width : '',
                },
              }}
              inputProps={{
                style: {
                  textAlign: props.field.config?.styling?.textAlign,
                  textDecorationLine: props.field.config?.styling?.textDecorationLine,
                  margin: borderWidth,
                },
              }}
              error={props.error || fileError}
              helperText={(() => {
                if (fileErrorMessage) {
                  return fileErrorMessage;
                } else {
                  return props.helperText;
                }
              })()}
            />
            {!['within-top', undefined].includes(labelPosition) && (
              <InputLabel
                style={{
                  position: 'absolute',
                  maxWidth: 'none',
                  fontSize: '0.75rem',
                  transition: 'none',
                  whiteSpace: 'nowrap',
                  ...labelPositionStyling,
                  ...labelStyling,
                  width: 'unset',
                  paddingRight: 'unset',
                }}
              >
                {`${props.field.config.title} ${props.field.config.required ? '*' : ''}`}
              </InputLabel>
            )}
          </div>
          {!!fileTypes.length && (
            <FormHelperText margin="dense" variant="filled">
              {`Accepted file types: ${fileTypes.join(', ')}`}
            </FormHelperText>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default memo(FileField);
