import {
  FormControl,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { DropDownField as DropDownFieldType, GenericFieldStyling } from '../../interfaces/FormFieldTypes';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { useNTFApi } from '../hooks/useNTFApi';
import { useAppConfig } from '../context/AppState/AppContext';
import { useEffect, memo } from 'react';
import { t } from 'i18next';

type Props = {
  field: DropDownFieldType;
};

const useStyles = makeStyles({
  root: (props: Props) => ({
    '& fieldset': {
      borderWidth: props.field.config.styling?.borderWidth
        ? `${props.field.config.styling?.borderWidth}px !important`
        : undefined,
      borderRadius: props.field.config.styling?.borderRadius
        ? `${props.field.config.styling?.borderRadius}px`
        : undefined,
      borderColor: props.field.config.styling?.borderColor
        ? `${props.field.config.styling?.borderColor} !important`
        : undefined,
      borderStyle: props.field.config.styling?.borderStyle,
      '& legend': {
        ...props.field.config.styling?.labelStyling,
      },
    },
  }),
});

const DropdownField = memo(({ field }: Props) => {
  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootAndTextStyling } =
    field.config.styling ?? {};
  const { fontFamily, fontSize, fontWeight, fontStyle, textDecorationLine, textAlign }: Partial<GenericFieldStyling> =
    rootAndTextStyling ?? {};

  const classes = useStyles({ field });
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);
  const { state: appState } = useAppConfig();
  const { updateJobTimer } = useNTFApi();

  useEffect(() => {
    if (field.config.readonly) return;
    function validateField(value: DropDownFieldType['value']): string {
      if (field.config.required) {
        if (!!!value.length) return t('error empty');
      }
      return '';
    }
    const error = validateField(field.value);
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field.value, dispatch, field.id, field.config]);

  function handleChange(e: any) {
    // The product team requested that the last updated job timestamp be updated whenever a form is updated.
    // See workflow-engine#35
    updateJobTimer(appState.processId);
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: field.config.multiSelect ? e.target.value : [e.target.value],
      },
    });
  }

  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: -10,
      top: '50%',
      transform: 'translate(calc(100%), -50%)',
      width: 'max-content',
      display: 'inline-block',
      marginLeft: 'auto',
    } as const,
    top: {
      top: 0,
      left: 14,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 14,
      transform: 'translate(0, 100%)',
      width: 'max-content',
      height: 'max-content',
      display: 'inline-block',
      marginTop: 'auto',
    } as const,
    'inside-top': {
      top: -16,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];

  return (
    <Grid item xs={12}>
      <FormControl size="small" fullWidth>
        <TextField
          className={classes.root}
          size="small"
          select
          SelectProps={{
            multiple: field.config.multiSelect,
            onChange: handleChange,
            renderValue: (selected: any) =>
              field.config.options
                .filter((option) =>
                  field.config.multiSelect ? selected.includes(option.value) : selected === option.value,
                )
                .map((option) => option.label)
                .join(', '),
          }}
          value={
            field.value.length === 0
              ? field.config.multiSelect
                ? []
                : ''
              : field.config.multiSelect
              ? field.value
              : field.value[0]
          }
          variant="outlined"
          label={
            ['within-top', undefined].includes(labelPosition)
              ? field.config.title + (field.config.required ? ' *' : '')
              : undefined
          }
          InputProps={{
            style: {
              height: '2em',
              ...rootAndTextStyling,
              borderRadius,
              overflowY: 'hidden',
              overflowX: 'hidden',
            },
          }}
          inputProps={{
            style: {
              textAlign: field.config?.styling?.textAlign,
              textDecorationLine: field.config?.styling?.textDecorationLine,
              margin: borderWidth,
            },
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              top: 0,
              left: 14,
              transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
              lineHeight: 1,
              ...labelStyling,
              fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
              marginLeft: borderWidth,
            },
          }}
          onChange={handleChange}
          disabled={field.config.readonly}
          error={!!fieldError}
          style={{ fontSize: '1rem' }}
        >
          {field.config.options
            .filter((option) => option.show)
            .map((option) => {
              return (
                <MenuItem key={option.id} value={option.value} style={{ minHeight: '2em' }}>
                  {field.config.multiSelect && (
                    <Checkbox color="primary" checked={field.value.includes(option.value)} />
                  )}
                  <Typography
                    variant="body1"
                    className="break-all whitespace-normal"
                    style={{
                      fontFamily,
                      fontSize,
                      fontWeight,
                      fontStyle,
                      textDecorationLine,
                      textAlign,
                      width: '100%',
                    }}
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              );
            })}
        </TextField>

        {!['within-top', undefined].includes(labelPosition) && (
          <InputLabel
            style={{
              position: 'absolute',
              maxWidth: 'none',
              fontSize: '0.75rem',
              transition: 'none',
              whiteSpace: 'nowrap',
              ...labelPositionStyling,
              ...labelStyling,
              // width: 'unset',
              paddingRight: 'unset',
            }}
          >
            {`${field.config.title} ${field.config.required ? '*' : ''}`}
          </InputLabel>
        )}

        <FormHelperText>
          <Typography variant="subtitle2" style={{ color: 'red' }}>
            {fieldError}
          </Typography>
        </FormHelperText>
      </FormControl>
    </Grid>
  );
});

const ButtonList = ({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  function handleClick(option: { label: string; value: string }) {
    if (field.config.multiSelect) {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: field.value.includes(option.value)
            ? field.value.filter((value) => value !== option.value)
            : [...field.value, option.value],
        },
      });
    } else {
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: [option.value],
        },
      });
    }
  }

  useEffect(() => {
    function validateField(value: DropDownFieldType['value']): string {
      if (field.config.required) {
        if (!!!value.length) return t('error empty');
      }
      return '';
    }
    if (field.config.readonly) return;
    const error = validateField(field.value);
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field.value, dispatch, field.id, field.config]);
  return (
    <Grid container spacing={1} direction="column">
      <Grid item xs={12}>
        <Typography variant="body1">{field.config.title}</Typography>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        {field.config.options
          .filter((option) => option.show)
          .map((option) => {
            const selected = field.value.includes(option.value);
            return (
              <Grid item key={option.id}>
                <Button
                  disabled={field.config.readonly}
                  style={{
                    opacity: selected ? '100%' : '50%',
                    minHeight: '100%',
                  }}
                  color={selected ? 'primary' : 'default'}
                  onClick={() => handleClick(option)}
                  variant="contained"
                >
                  <span className="break-all">{option.label}</span>
                </Button>
              </Grid>
            );
          })}
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ color: 'red' }}>
          {fieldError}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DropdownWrapper = ({ field }: Props) => {
  if (field.config.buttonsList) return <ButtonList field={field} />;
  return <DropdownField field={field} />;
};

export default memo(DropdownWrapper);
